import React from 'react'
import '../Reasons/Reasons.css'
import image1 from "../../asset/image1.png"
import image2 from "../../asset/image2.png"
import image3 from "../../asset/image3.png"
import image4 from "../../asset/image4.png"
import nb from "../../asset/nb.png"
import adidas from "../../asset/adidas.png"
import nike from "../../asset/nike.png"
import tick from "../../asset/tick.png"


export const Reasons = () => {
  return (
    <div className="reasons" id="reasons">
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className="right-r">
        <span>Some reasons</span>
        <div>
          <span className="stroke-text">Why </span>
          <span>Choose Us?</span>
        </div>

        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>over 140+ expert coaches</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>train smarter and faster than before</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>1 free program for new member</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>reliable partners</span>
          </div>
        </div>
        <span style={{
          color: "gray",
          fontWeight: "normal",
        }}
        >
          our partners</span>

        <div className="partners">
          <img src={nb} alt="" />
          <img src={adidas} alt="" />
          <img src={nike} alt="" />
        </div>

      </div>
    </div>
  );
}

export default Reasons;