import React, { useState } from 'react'
import './Header.css'
import logo from '../../asset/logo.png'
import Bars from '../../asset/bars.png'
import { Link } from "react-scroll"
export const Header = () => {

    const mobile = window.innerWidth<=768 ? true: false;
    const [menuOpened, setMenuOpened] = useState(false)
    return (
    <div className='Header'>
      <img className='logo' src={logo} alt="" />
      {(menuOpened===false && mobile===true)? (
        <div 
        style={{backgroundColor: '#3c3f45',
        padding: '0.5rem',
        borderRadius: "5px",
        cursor: 'pointer'
        }}
          onClick={()=> setMenuOpened(true)}
        >
          <img src={Bars} alt="" style={{width: '1.5rem', height: '1.5rem'}} /></div>
      ) : (
      <ul className='Header-menu'>
      <li><Link 
      onClick={()=>setMenuOpened(false)}
      to='Home'
      activeClass='active'
      span="true"
      smooth={true}      
      >Home</Link></li>
      <li><Link
       onClick={()=>setMenuOpened(false)}
       to='programs'
       span="true"
       smooth={true}   
      >Programs</Link></li>
      <li><Link
       onClick={()=>setMenuOpened(false)}
       to='reasons'
       span="true"
       smooth={true}  
      >Why Us</Link></li>
      <li><Link
       onClick={()=>setMenuOpened(false)}
       to='Plans'
       span="true"
       smooth={true}  
      >Plans</Link></li>
      <li><Link
       onClick={()=>setMenuOpened(false)}
       to='testimonials'
       span="true"
       smooth={true}
       >Testimonials</Link></li>
    </ul>
    )}
      </div>
  );
};

 export default Header;
