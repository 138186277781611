import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {

    const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mmuhdym', 'template_l5pkn8j', form.current, 'zrcKzCt4PieJNKerS')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
  };

  return (
    <div className='Join' id='Join-us'>
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>ready to</span>
                <span>level up</span>
            </div>
            <div>
                <span>your body</span>
                <span className='stroke-text'>with us?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Enter your Email Address' />
                <button type='submit' className="btn btn-j">Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join;