import React from 'react'
import '../Programs/Programs.css'
import {programsData} from '../../data/programsData'
import RightArrows from '../../asset/rightArrow.png'

export const Programs = () => {
  return (
    <div className="programs" id="Programs">
        <div className="programs-header">
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text'>to shape you</span>
        </div>
        <div className="programs-categories">
            {programsData.map ((program, i)=> (
                <div className="category" key={i}>
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                    <div className="join-now"><span>Join Now</span><img src={RightArrows} alt="" /></div>
                </div>
            ))}
        </div>
    </div>
  )
}



export default Programs;